import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './navbar.css';
import logo from "../../image/logo11.png";
const Navbar = () => {
 const navigate = useNavigate();
 const [dropdownOpen, setDropdownOpen] = useState(false);
 const [menuOpen, setMenuOpen] = useState(false);
 const toggleDropdown = () => {
   setDropdownOpen(!dropdownOpen);
 };
 const toggleMenu = () => {
   setMenuOpen(prev => !prev);
   setDropdownOpen(false); // Close dropdown when menu is toggled
 };
 return (
<nav className="navbar">
<div className="navbar-left">
<img
         src={logo}
         alt="Logo"
         className="logo"
         onClick={() => { toggleMenu(); navigate('/'); }}
       />
</div>
<div className="navbar-right">
       {menuOpen ? null : (
<button className="hamburger" onClick={toggleMenu}>
&#9776;
</button>
       )}
<ul className={`nav-menu ${menuOpen ? 'open' : ''}`}>
<li onClick={() => { toggleMenu(); navigate('/'); }}>Home</li>
<li onClick={toggleDropdown} className="dropdown">
           Our Products&nbsp;&nbsp;      
           {dropdownOpen && (
<ul className="dropdown-menu">
<li onClick={() => { toggleMenu(); navigate('/PressureFormer'); }}>Pressure Former</li>
<li onClick={() => { toggleMenu(); navigate('/Cylinder-Mould'); }}>Cylinder Mould</li>
<li onClick={() => { toggleMenu(); navigate('/Spiderless-Cylinder-Mould'); }}>Spiderless Cylinder Mould</li>
<li onClick={() => { toggleMenu(); navigate('/1524-Pressure-Former'); }}>1524 Pressure Former</li>
<li onClick={() => { toggleMenu(); navigate('/Extractor-Press'); }}>Extractor Press</li>
<li onClick={() => { toggleMenu(); navigate('/Blade-Bar-Coater'); }}>Blade/Bar Coater</li>
<li onClick={() => { toggleMenu(); navigate('/Bar-Coater-V3-&-V2'); }}>Bar Coater V3 & V2</li>
<li onClick={() => { toggleMenu(); navigate('/Size-Press'); }}>Size Press</li>
<li onClick={() => { toggleMenu(); navigate('/Coating-Disperser-&-Coating-Kitchen-Equipment'); }}>Coating Disperser & Coating Kitchen Equipment</li>
<li onClick={() => { toggleMenu(); navigate('/Roll-Cleaner'); }}>Roll Cleaner</li>
</ul>
           )}
</li>
<li onClick={() => { toggleMenu(); navigate('/SparesAndServices'); }}>Spares and Services&nbsp;&nbsp;&nbsp;</li>
</ul>
</div>
</nav>
 );
};
export default Navbar;