import React from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import './App.css';
import Navbar from './Component/navBar/navbar';
import Footer from './Component/footer/footer';
import Homepage from './Component/homepage/homepage';
import PressureFormer from './Component/presserformer/presserformer';
import Former from './Component/Former/Former';
import Cylinder from './Component/cylinder/cylinder';
import Spiderless from './Component/spiderless/spiderless';
import Blade from './Component/blade/blade';
import Coater from './Component/coater/coater';
import Size from './Component/size/size';
import Coating from './Component/coating/coating';
import Roll from './Component/roll/roll';
import Vat from './Component/vat/vat';
import Sparesandservices from './Component/Sparesandservices/Sparesandservices';
import Details from './Component/details/details';
function App() {
 return (
<Router>
<div className="App">
<Navbar /> {/* Navbar outside Routes to remain persistent */}
<Routes>
<Route path="/" element={<Homepage />} />
<Route path="/PressureFormer" element={<PressureFormer />} />
<Route path="/1524-Pressure-Former" element={<Former />} />
<Route path="/Cylinder-Mould" element={<Cylinder />} />
<Route path="/Spiderless-Cylinder-Mould" element={<Spiderless />} />
<Route path="/Blade-Bar-Coater" element={<Blade />} />
<Route path="/Bar-Coater-V3-&-V2" element={<Coater />} />
<Route path="/Size-Press" element={<Size />} />
<Route path="/Coating-Disperser-&-Coating-Kitchen-Equipment" element={<Coating />} />
<Route path="/Roll-Cleaner" element={<Roll />} />
<Route path="/Extractor-Press" element={<Vat />} />
<Route path="/Details" element={<Details />} />
<Route path="/SparesAndServices" element={<Sparesandservices />} />
</Routes>
<Footer />
</div>
</Router>
 );
}
export default App;